import React, { Component } from "react";
import { withRouter } from "../../common/with-router";
import BuildService from "../../services/build.service";
import { RequestMessageParser } from "../../common/message-parser";
import BuildSettings from "./build-settings-component";
import Card from "react-bootstrap/Card";
import { API_DOWNLOAD_URL } from "../../common/constants";
import QRCode from "qrcode.react";
import Spinner from "react-bootstrap/Spinner";
import { SketchPicker } from "react-color";
import ProgressBar from "react-bootstrap/ProgressBar";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Breadcrumb from "../common/breadcrumb-component";

class BuildComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      appId: this.props.router.params.appId,
      message: null,
      status: null,
      downloadUrl: null,
      iconFile: null,
      splashFile: null,
      settings: {
        appinfo: {
          url: "",
          appicon: "default.ico",
          appname: "",
          apptype: "android",
          packagename: "",
          versioncode: null,
          versionname: "",
        },
        appsettings: {
          statusbarcolor: "",
          screenorientation: "",
        },
        splashscreen: {
          sstype: "",
          logoimage: "",
          backgroundcolor: "",
        },
      },
      intervalId: null,
      iconUrl: null,
      splashUrl: null,
      progress: 0,
      buildStatusMessage: "Building",
      paymentStatus: "",
      price: "5.00",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleIconFileChange = this.handleIconFileChange.bind(this);
    this.handleSplashFileChange = this.handleSplashFileChange.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    //Get App Settings
    BuildService.GetAppSettings(this.state.appId).then(
      (response) => {
        this.setState({
          isLoading: false,
          settings: response.data.settings,
        });
        const appIconUrl = `${API_DOWNLOAD_URL}/${this.state.appId}/${response.data.settings.appinfo.appicon}`;
        const appSplashUrl = `${API_DOWNLOAD_URL}/${this.state.appId}/${response.data.settings.splashscreen.logoimage}`;

        const fetchImage = (url, stateKey) => {
          fetch(url)
            .then((response) => {
              if (response.ok) {
                this.setState({
                  [stateKey]: url,
                });
              }
            })
            .catch((error) => {
              console.error("Error checking image URL:", error);
            });
        };

        fetchImage(appIconUrl, "iconUrl");
        fetchImage(appSplashUrl, "splashUrl");
      },
      (error) => {
        this.setState({
          loading: false,
          message: RequestMessageParser(error),
        });
      }
    );

    this.trigerAppStatus(this.state.appId);
    this.checkAppStatus(this.state.appId);
  }

  handlePaymentSuccess = () => {
    // Update the payment status in the state
    this.setState({ paymentStatus: "Success" });
  };

  handlePaymentApproval = (data, actions) => {
    // Capture the funds from the approved order
    return actions.order.capture().then((details) => {
      // Call your own API to save the transaction details
      console.log(details);
      // Update the payment status in the state
      this.setState({ paymentStatus: "Approved" });
    });
  };

  checkAppStatus(aAppID) {
    BuildService.GetAppStatus(this.state.appId).then(
      (response) => {
        if (response.data.status === "Finish") {
          clearInterval(this.state.intervalId);
          this.setState({
            downloadUrl: API_DOWNLOAD_URL + "/" + this.state.appId + ".apk",
            progress: 0,
          });
        } else if (response.data.status === "Error") {
          this.setState({
            buildStatusMessage: "Error",
          });
          clearInterval(this.state.intervalId);
        } else {
          this.setState({
            progress: response.data.status,
          });
        }
      },
      (error) => {
        clearInterval(this.state.intervalId);
        this.setState({
          isLoading: false,
          message: RequestMessageParser(error),
          buildStatusMessage: "Error",
        });
      }
    );
  }

  trigerAppStatus(aAppID) {
    const intervalId = setInterval(
      () => this.checkAppStatus(this.state.appId),
      1000
    );
    this.setState({ intervalId: intervalId });
  }

  handleSubmit(event) {
    event.preventDefault();

    BuildService.SaveSettings(
      this.state.appId,
      [this.state.iconFile, this.state.splashFile],
      this.state.settings
    ).then(
      (response) => {
        this.setState({
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          message: RequestMessageParser(error),
        });
      }
    );
  }

  handleSplashFileChange(event) {
    this.setState({
      splashFile: event.target.files[0],
      splashUrl: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleIconFileChange(event) {
    this.setState({
      iconFile: event.target.files[0],
      iconUrl: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleRebuildApp = () => {
    this.setState({ downloadUrl: "", buildStatusMessage: "Building" });
    BuildService.BuildApp(this.state.appId).then(
      (response) => {
        // Handle the rebuild success response
        this.trigerAppStatus(this.state.appId);
      },
      (error) => {
        this.setState({
          // isLoading: false,
          message: RequestMessageParser(error),
        });
      }
    );
  };

  render() {
    const { paymentStatus } = this.state;
    return (
      <div>
        <Breadcrumb title="Build Aplication" />
        <div className="container">
          {/* <BuildSettings settings={this.state.settings} /> */}

          {/* {this.state.settings &&
          this.state.settings.appinfo &&
          this.state.settings.appinfo.appname} */}
          <div className="row">
            <div className="col-sm-8">
              <Card>
                <Card.Body>
                  {!this.state.isLoading && (
                    <form onSubmit={this.handleSubmit}>
                      <div className="mb-4">
                        <label htmlFor="appName" className="form-label">
                          App Name:
                          <div className="info-text">
                            Your App Name is displayed on the device home
                            screen.
                          </div>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="appName"
                          placeholder="Example App"
                          value={this.state.settings.appinfo.appname}
                          onChange={(event) => {
                            const updatedSettings = {
                              ...this.state.settings,
                              appinfo: {
                                ...this.state.settings.appinfo,
                                appname: event.target.value,
                              },
                            };
                            this.setState({ settings: updatedSettings });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="versionCode" className="form-label">
                          Package Name:
                          <div className="info-text">
                            Your App Package Name.
                          </div>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="versionCode"
                          placeholder="Version Code"
                          value={this.state.settings.appinfo.packagename}
                          onChange={(event) => {
                            const updatedSettings = {
                              ...this.state.settings,
                              appinfo: {
                                ...this.state.settings.appinfo,
                                packagename: event.target.value,
                              },
                            };
                            this.setState({ settings: updatedSettings });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="versionCode" className="form-label">
                          Version Code:
                          <div className="info-text">
                            Your App version code.
                          </div>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="versionCode"
                          placeholder="Version Code"
                          value={this.state.settings.appinfo.versioncode}
                          onChange={(event) => {
                            const updatedSettings = {
                              ...this.state.settings,
                              appinfo: {
                                ...this.state.settings.appinfo,
                                versioncode: event.target.value,
                              },
                            };
                            this.setState({ settings: updatedSettings });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="versionCode" className="form-label">
                          Version name:
                          <div className="info-text">
                            Your App version name.
                          </div>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="versionCode"
                          placeholder="Version Code"
                          value={this.state.settings.appinfo.versionname}
                          onChange={(event) => {
                            const updatedSettings = {
                              ...this.state.settings,
                              appinfo: {
                                ...this.state.settings.appinfo,
                                versionname: event.target.value,
                              },
                            };
                            this.setState({ settings: updatedSettings });
                          }}
                        />
                      </div>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Icon:
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              onChange={this.handleIconFileChange}
                              id="image"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {this.state.iconUrl && (
                            <div className="App">
                              <img
                                alt="test"
                                className="img-thumbnail upload-image"
                                src={this.state.iconUrl}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Splash screen:
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              onChange={this.handleSplashFileChange}
                              id="image"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {this.state.splashUrl && (
                            <div className="App">
                              <img
                                alt="test"
                                className="img-thumbnail upload-image"
                                src={this.state.splashUrl}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="statusbarColor" className="form-label">
                          Status Bar Color:
                        </label>
                        <div className="color-picker">
                          <SketchPicker
                            color={
                              this.state.settings.appsettings.statusbarcolor
                            }
                            onChangeComplete={(color) => {
                              const updatedSettings = {
                                ...this.state.settings,
                                appsettings: {
                                  ...this.state.settings.appsettings,
                                  statusbarcolor: color.hex,
                                },
                              };
                              this.setState({ settings: updatedSettings });
                            }}
                          />
                        </div>
                      </div>
                      <br></br>
                      {this.state.message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      <br></br>
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        <span>Save Settings</span>
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm spinner-margin"></span>
                        )}
                      </button>
                    </form>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-4">
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-center">
                    {this.state.downloadUrl ? (
                      <div>
                        <a
                          className="qr-download"
                          href={this.state.downloadUrl}
                          download
                        >
                          Download android APK
                        </a>
                        <br />
                        <br />
                        <div className="qr-download">
                          <QRCode size={200} value={this.state.downloadUrl} />
                        </div>
                        <br />

                        <button
                          className="btn btn-primary btn-block"
                          type="button"
                          onClick={this.handleRebuildApp}
                        >
                          <span>Rebuild App</span>
                        </button>

                        <br></br>
                        <br></br>
                        <PayPalScriptProvider
                          options={{
                            "client-id":
                              "AfkbWq6mq5eqWdmCv62XEzSnF6BGdBMdIVbmXT4VdCD3a3dRxKr_22b2B6PrFsRbs_lk8_DHS-AqVjC5",
                          }}
                        >
                          {paymentStatus !== "Approved" && ( // Hide buttons if paymentStatus is "Success"
                            <PayPalButtons
                              createOrder={(data, actions) => {
                                // Create an order and return the order ID
                                return actions.order.create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        value: this.state.price, // Example: The payment amount
                                      },
                                      custom_id: this.state.appId,
                                    },
                                  ],
                                });
                              }}
                              onApprove={this.handlePaymentApproval}
                              onSuccess={this.handlePaymentSuccess}
                            />
                          )}
                        </PayPalScriptProvider>

                        {paymentStatus && (
                          <div className="notification">
                            Payment{" "}
                            {paymentStatus === "Success"
                              ? "successful!"
                              : "approved!"}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="loader qr-download">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">
                              {this.state.buildStatusMessage}...
                            </span>
                          </Spinner>
                          <div>{this.state.buildStatusMessage}...</div>
                        </div>
                        <ProgressBar
                          now={this.state.progress}
                          label={`${this.state.progress}%`}
                        />
                        {this.state.buildStatusMessage === "Error" && (
                          <div>
                            <br></br>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={this.handleRebuildApp}
                            >
                              <span>Rebuild App</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BuildComponent);
