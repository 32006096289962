import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-276399147-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorker.unregister();
