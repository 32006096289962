import React, { Component } from "react";
import { withRouter } from "../../common/with-router";
import axios from "axios";
import { API_URL } from "../../common/constants";
import authHeader from "../../services/auth-header";

export class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      url: "",
      txtMessage: "https://",
      isFormClicked: false,
      apiResponse: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange = (e) => {
    this.setState({ url: e.target.value });
  };

  // Define a function to handle the button click
  async handleClick() {
    try {
      this.setState({ isLoading: true });
      const isEmptyUrl = this.state.url !== "";
      if (!isEmptyUrl) {
        this.setState({
          apiResponse: { success: false, error: "Please enter url" },
          isLoading: false,
          txtMessage: "Please enter website url",
        });
        return;
      }

      this.executeIsValidUrl();
    } catch (error) {
      console.error(error);
    }
  }

  async executeIsValidUrl() {
    const response = await axios.get(API_URL + "/isvalidurl", {
      params: {
        url: this.state.url,
      },
      headers: authHeader(),
    });

    // Set the API response in state
    this.setState({ apiResponse: response.data, isLoading: false });
    if (response.data.success === false) {
      this.setState({
        apiResponse: { success: false, error: "Invalid URL" },
        txtMessage: "Invalid URL",
      });
    } else {
      const encodedUrl = encodeURIComponent(response.data.url);
      const encodedAppName = encodeURIComponent(response.data.appName);
      this.props.router.navigate(
        "/prebuild/" + encodedUrl + "/" + encodedAppName
      );
    }
  }

  handleFormClick = () => {
    this.setState({ isFormClicked: true });
  };

  componentDidMount() {
    // const currentUser = AuthService.getCurrentUser();
    // console.log(
    //   AuthService.parseJwt(currentUser?.token ?? "")?.UserID ?? "Nema"
    // );
  }

  render() {
    return (
      <div>
        <section
          className="hero-area hero-space-1 bg-top-left pt-190 pb-150"
          data-background="assets/img/hero-bg.jpg"
          data-bg-color="#eeeff4"
          style={{
            backgroundImage: 'url("assets/img/hero-bg.jpg")',
            backgroundColor: "rgb(238, 239, 244)",
          }}
        >
          <div className="container">
            <div className="hero-inner p-rel">
              <div className="hero-shapes">
                <div className="shape1 p-abs">
                  <img
                    src="./Sasup - Sass Landing HTML5 Template_files/shape_01.png"
                    alt=""
                  />
                </div>
                <div className="shape2 p-abs">
                  <img
                    src="./Sasup - Sass Landing HTML5 Template_files/shape_02.png"
                    alt=""
                  />
                </div>
                <div className="shape3 p-abs d-none d-xl-block">
                  <img
                    src="./Sasup - Sass Landing HTML5 Template_files/shape_03.png"
                    alt=""
                  />
                </div>
                <div className="shape4 p-abs">
                  <img
                    src="./Sasup - Sass Landing HTML5 Template_files/shape_04.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="sasup-hero-content text-center">
                <span
                  className="sasup-hero-content-sub wow fadeInUp"
                  data-wow-delay="0s"
                >
                  No mobile development required!
                </span>
                <h3
                  className="sasup-hero-content-main wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  Convert Your Website to
                  <br />
                  Mobile App Now!
                </h3>
                <div
                  className="sasup-hero-form mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="sasup-hero-form-inner p-rel d-flex align-items-center">
                    <div
                      className="sasup-form-group"
                      onClick={this.handleFormClick}
                    >
                      <input
                        type="text"
                        value={this.state.url}
                        onChange={this.handleChange}
                        id="hero-email"
                      />
                      <label
                        htmlFor="hero-email"
                        className={
                          this.state.isFormClicked
                            ? "epix-hero-form-label epix-hero-form-clicked"
                            : "epix-hero-form-label"
                        }
                      >
                        {this.state.txtMessage}
                      </label>
                    </div>
                    <div className="sasup-form-group">
                      <button
                        onClick={this.handleClick}
                        className="btn btn-success"
                        type="button"
                        disabled={this.state.isLoading}
                      >
                        Request Now, IT’S FREE!
                        {this.state.isLoading && (
                          <span className="spinner-border spinner-border-sm spinner-margin"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="sasup-form-group mb-55 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="started-label">
                    <label htmlFor="hero-email">
                      Get started for free
                      <img src="assets/img/arrow.png" alt="" />
                    </label>
                  </div>
                </div>
                <div className="dashbord-img dashbord-shadow d-inline-block fix">
                  <img
                    src="assets/img/dashbord-img.jpg"
                    className="img-fluid wow bounceInUp"
                    data-wow-delay=".8s"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(HeroSection);
