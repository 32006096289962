import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import { RequestMessageParser } from "../../common/message-parser";
import { withRouter } from "../../common/with-router";
import backImage from "../../assets/back.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export class LoginState extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleResendActivationClick =
      this.handleResendActivationClick.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleStateClick = this.handleStateClick.bind(this);
    this.state = {
      formState: "login",
      username: "",
      password: "",
      loading: false,
      message: "",
      reactivation: false,
      forgetPassword: false,
    };
  }

  clearState() {
    this.setState({
      formState: "login",
      username: "",
      password: "",
      loading: false,
      message: "",
      reactivation: false,
      forgetPassword: false,
    });
  }

  handleStateClick(state) {
    this.props.onChildEvent(state);
    if (state === "login") {
      this.clearState();
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleResendActivationClick(e) {
    e.preventDefault();
    this.setState({ loading: true });
    AuthService.resendActivationLink(this.state.username).then(
      () => {
        //  this.props.router.navigate("/profile");
        // window.location.reload();
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({
          loading: false,
          message: RequestMessageParser(error),
          //reactivationRequired: error.response.data.code === 40152,
        });
      }
    );
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.loginform.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password, "user").then(
        () => {
          this.props.router.navigate("/profile");
          window.location.reload();
        },
        (error) => {
          this.setState({
            loading: false,
            message: RequestMessageParser(error),
            reactivation:
              error.response &&
              error.response.data &&
              error.response.data.code === 40111,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <div>
        {!this.state.reactivation && (
          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.loginform = c;
            }}
          >
            <div className="title">
              <h3 className="mb-30">Login to GoMobileBuild</h3>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-20">
                  <label htmlFor="email" className="form-label color-dark">
                    Email
                    <span className="color-red">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required]}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group mb-20">
                  <label htmlFor="password" className="form-label color-dark">
                    Password
                    <span className="color-red">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Enter password"
                      required
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                    />
                    <span className="show-password-field">
                      <i className="show-icon"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="custom-checkbox mb-10 font-sm">
                  <input
                    className="input-checkbox"
                    type="checkbox"
                    name="checkbox"
                    id="checkbox4"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="checkbox4">
                    <span>
                      I agree with GoMobileBuild's{" "}
                      <a href="terms-conditions.html">Terms & Conditions</a>
                    </span>
                  </label>
                </div>
                <div className="custom-checkbox mb-10 font-sm">
                  <input
                    className="input-checkbox"
                    type="checkbox"
                    name="checkbox"
                    id="checkbox5"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="checkbox5">
                    <span>
                      I’d like being informed about latest news and tips
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center mt-10 mb-15">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100"
                title="Login"
                disabled={this.state.loading}
              >
                Login
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm spinner-margin"></span>
                )}
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap gap-2">
              <div className="link font-sm">
                <label
                  onClick={() => this.handleStateClick("forgot-password")}
                  title="Forgot Password"
                >
                  Forgot password?
                </label>
              </div>
              <div className="link font-sm">
                Don't have an account?{" "}
                <label
                  title="Go Signup"
                  onClick={() => this.handleStateClick("register")}
                >
                  Click Here
                </label>{" "}
                to Signup
              </div>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Email
              </label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div> */}

            {/* <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
              <label
                className="forgot-password"
                onClick={() => this.handleStateClick("forgot-password")}
              >
                Forgot your password?
              </label>
            </div> */}
            {/* 
            <div className="mb-3">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm spinner-margin"></span>
                )}
                <span>Login</span>
              </button>
            </div> */}

            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        )}
        {this.state.message && (
          <div className="mt-5">
            <div className="alert alert-danger" role="alert">
              {this.state.message}
            </div>
          </div>
        )}
        {this.state.reactivation && (
          <div>
            <img
              className="back-button"
              onClick={() => this.handleStateClick("login")}
              alt="back"
              src={backImage}
            />
            <button
              onClick={this.handleResendActivationClick}
              disabled={this.state.loading}
              className="btn btn-warning btn-block"
            >
              Resend Activation link
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm spinner-margin"></span>
              )}
            </button>{" "}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(LoginState);
