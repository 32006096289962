import { useState, useRef } from "react";
import { useParams } from "react-router";
import AuthService from "../../services/auth.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { RequestMessageParser } from "../../common/message-parser";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ResetPasswordPage = () => {
  const { code } = useParams();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successed, setSuccessed] = useState(false);

  const form = useRef(null);

  const onChangePassword = (e) => {
    setpassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.current.validateAll();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    } else {
      setPasswordError("");
    }
    setLoading(true);
    AuthService.ResetPassword(password, code).then(
      () => {
        setLoading(false);
        setSuccessed(true);
        // Redirect to success page or display success message
      },
      (error) => {
        setMessage(RequestMessageParser(error));
        setLoading(false);
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        {!successed && (
          <Form onSubmit={handleSubmit} ref={form}>
            <div className="mb-3">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="confirmPassword">Confirm password</label>
              <Input
                type="password"
                className="form-control"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                validations={[required]}
              />
            </div>

            {passwordError && (
              <div className="alert alert-danger" role="alert">
                {passwordError}
              </div>
            )}

            {message && (
              <div className="mb-3">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}

            <div className="mb-3">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
                onClick={handleSubmit}
              >
                <span>Set new password</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm spinner-margin"></span>
                )}
              </button>
            </div>
          </Form>
        )}
        {successed && (
          <div className="mb-3">
            <div className="alert alert-info" role="alert">
              Congratulations! Your password has been updated successfully.
              Please click on the login button in navigation bar to access your
              account with your new password. Remember to keep your new password
              safe and never share it with anyone. If you have any questions or
              concerns, please don't hesitate to contact us. Thank you for using
              our service!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
