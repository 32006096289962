import React from "react";
import backgroundImage from "../../assets/hero-bg.jpg";

const Breadcrumb = ({ title }) => {
  return (
    <div
      className={`breadcrumb-area bg-default ${
        title ? "pt-80" : "pt-25"
      } pb-40`}
      data-backgrou={backgroundImage}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "white",
      }}
    >
      <div className="container">
        <div className="breadcrumb-content text-center">
          <h3 className="breadcrumb-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
