import { useState, useEffect } from "react";
import { useParams } from "react-router";
import AuthService from "../../services/auth.service";
import { RequestMessageParser } from "../../common/message-parser";
import Breadcrumb from "../common/breadcrumb-component";

const ActivationPage = () => {
  const { code } = useParams();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    if (code) {
      AuthService.activation(code).then(
        () => {
          setLoading(false);
          setSuccessful(true);
          setMessage(
            "Congratulations! Your account has been successfully activated. You're now ready to start exploring and using all the features our platform has to offer. Thank you for choosing our service, and if you have any questions or need any assistance, don't hesitate to reach out to our customer support team. Happy browsing!"
          );
        },
        (error) => {
          setMessage(RequestMessageParser(error));
          setLoading(false);
          setSuccessful(false);
        }
      );
    }
  }, [code]);

  return (
    <div>
      <Breadcrumb title="Activation" />
      <div className="container">
        <header className="jumbotron mt-5">
          {loading && <h4>Loading...</h4>}
          {message && !loading && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </header>
      </div>
    </div>
  );
};

export default ActivationPage;
