//This is importing axios module
import axios from "axios";
import { API_URL, API_URL_EXECUTE } from "../common/constants";
import authHeader from "./auth-header";

//This is a service class that has several methods to interact with authentication API endpoints
class AuthService {
  //This method logs an user in and returns a promise that resolves to an object containing access token
  //tokenType cookie public / user
  login(username, password, tokenType) {
    return axios
      .get(API_URL + "/gettoken", {
        headers: {
          user: username,
          password: password,
        },
      })
      .then((response) => {
        if (response && response.data && response.data.token) {
          const userData = {
            token: response.data.token,
            username: username,
            roles: ["ADMIN"],
          };
          localStorage.setItem(tokenType, JSON.stringify(userData));
          return response.data;
        } else {
          throw new Error("Invalid response received");
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        throw error;
      });
  }

  //This method logs an user out by removing their stored information from local storage
  logout() {
    localStorage.removeItem("user");
  }

  //This method creates a new account by sending a request to signup endpoint with given credentials
  register(email, password) {
    const postData = {
      // example JSON payload
      p_username: email,
      p_password: password,
    };
    return axios.post(API_URL + API_URL_EXECUTE + "/CreateUser", postData, {
      headers: authHeader(),
    });
  }

  resendActivationLink(aEmail) {
    return axios.post(
      API_URL + API_URL_EXECUTE + "/sendactivation",
      { p_email: aEmail },
      { headers: authHeader() }
    );
  }

  activation(aToken) {
    return axios.post(
      API_URL + API_URL_EXECUTE + "/activateuser",
      {},
      {
        headers: {
          token: aToken,
        },
      }
    );
  }

  sendResetPassword(aEmail) {
    return axios.post(
      API_URL + API_URL_EXECUTE + "/SendResetPassword",
      { p_email: aEmail },
      { headers: authHeader() }
    );
  }

  ResetPassword(aPassword, aToken) {
    return axios.post(
      API_URL + API_URL_EXECUTE + "/ResetPassword",
      { p_password: aPassword },
      {
        headers: {
          token: aToken,
        },
      }
    );
  }

  //This method retrieves an user object from local storage and parses it before returning.
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  parseJwt(token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
}

//Exporting an instance of the Authservice Class
export default new AuthService();
