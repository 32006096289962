import React, { Component } from "react";
import Breadcrumb from "./common/breadcrumb-component";
import Card from "react-bootstrap/Card";

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    // UserService.getAdminBoard().then(
    //   response => {
    //     this.setState({
    //       content: response.data
    //     });
    //   },
    //   error => {
    //     this.setState({
    //       content:
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString()
    //     });
    //
    //     if (error.response && error.response.status === 401) {
    //       EventBus.dispatch("logout");
    //     }
    //   }
    // );
  }

  render() {
    return (
      <div>
        <Breadcrumb title="Admin Access" />
        <div className="container">
          <Card>
            <Card.Body>
              <h3>Admin Access</h3>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
