import React, { useEffect } from "react";
import { withRouter } from "./with-router";
import AuthService from "../services/auth.service";

const AuthVerify = (props) => {
  let location = props.router.location;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = AuthService.parseJwt(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  }, [location, props]);

  return <div></div>;
};

export default withRouter(AuthVerify);
