import React, { Component } from "react";
import { withRouter } from "../../common/with-router";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import buildService from "../../services/build.service";
import { RequestMessageParser } from "../../common/message-parser";
import Breadcrumb from "../common/breadcrumb-component";
import Card from "react-bootstrap/Card";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class PreBuildComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      url: this.props.router.params.url,
      appName: this.props.router.params.appName,
      email: "",
      appType: "android",
    };
  }

  handlePreBuildClick = (e) => {
    e.preventDefault();

    this.loginform.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({ isLoading: true });
      buildService
        .CreateApp(
          this.state.email,
          this.state.appName,
          this.state.appType,
          this.state.url
        )
        .then(
          (response) => {
            this.setState({ loading: false });
            const encodedUrl = encodeURIComponent(response.data.appid);
            this.props.router.navigate("/build/" + encodedUrl);
            console.log(response.data);
          },
          (error) => {
            this.setState({
              isLoading: false,
              message: RequestMessageParser(error),
              //reactivationRequired: error.response.data.code === 40152,
            });
          }
        );
    }
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <Breadcrumb title="Build Aplication" />
        <div className="container">
          <Card>
            <Card.Body>
              <Form
                onSubmit={this.handlePreBuildClick}
                ref={(c) => {
                  this.loginform = c;
                }}
              >
                <div className="mb-4">
                  <label htmlFor="url" className="form-label">
                    Website URL
                    <div className="info-text">
                      The webpage to display when your app loads.
                    </div>
                  </label>
                  <Input
                    type="url"
                    className="form-control"
                    id="url"
                    placeholder="name@example.com"
                    value={this.state.url}
                    validations={[required]}
                    onChange={(event) =>
                      this.setState({ url: event.target.value })
                    }
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="appName" className="form-label">
                    App Name
                    <div className="info-text">
                      Your App Name is displayed on the device home screen.
                    </div>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="appName"
                    placeholder="App Name"
                    value={this.state.appName}
                    validations={[required]}
                    onChange={(event) =>
                      this.setState({ appName: event.target.value })
                    }
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="email" className="form-label">
                    Contact
                    <div className="info-text">
                      A valid email is required to secure access to your app. We
                      never share with third parties.
                    </div>
                  </label>
                  <Input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    value={this.state.email}
                    validations={[required]}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </div>
                <button
                  className="btn btn-primary mt-4"
                  disabled={this.state.isLoading}
                >
                  <span>Start Building my App!</span>
                  {this.state.isLoading && (
                    <span className="spinner-border spinner-border-sm spinner-margin"></span>
                  )}
                </button>
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(PreBuildComponent);
