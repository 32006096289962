import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../../services/auth.service";
import { RequestMessageParser } from "../../common/message-parser";
import backImage from "../../assets/back.png";

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      message: "",
      loading: false,
      mailSent: false,
    };

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.handleForgetPassword = this.handleForgetPassword.bind(this);
    this.handleStateClick = this.handleStateClick.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
      loading: false,
    });
  }

  handleForgetPassword(e) {
    e.preventDefault();
    this.setState({ message: "" });
    if (this.validateForm()) {
      this.setState({ loading: true });

      AuthService.sendResetPassword(this.state.username)
        .then(() => {
          this.setState({ loading: false, mailSent: true });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            message: RequestMessageParser(error),
          });
        });
    }
  }

  handleStateClick() {
    this.props.onChildEvent("login");
  }

  validateForm() {
    // Trigger manual validation of the form
    this.form.validateAll();

    // Check if the form has errors
    if (this.checkBtn.context._errors.length === 0) {
      return true; // Form is valid
    }

    return false; // Form is invalid
  }

  render() {
    return (
      <div>
        <img
          className="back-button"
          onClick={this.handleStateClick}
          alt="back"
          src={backImage}
        />
        {!this.state.mailSent && (
          <Form
            onSubmit={this.handleForgetPassword}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="title">
              <h3 className="mb-30">Forgot password?</h3>
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Email
              </label>
              <Input
                type="email"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required, email]}
              />
            </div>

            <div className="mb-3">
              <button
                className="btn btn-lg btn-primary w-100"
                disabled={this.state.loading}
              >
                <span>Reset Password</span>
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm spinner-margin"></span>
                )}
              </button>
            </div>

            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        )}
        {this.state.mailSent && (
          <div className="form-group">
            <div className="alert alert-info" role="alert">
              We have sent you an email with a link to reset your password. Did
              not receive the email? Check that you have entered the correct
              e-mail address.
            </div>
          </div>
        )}
        {this.state.message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {this.state.message}
            </div>
          </div>
        )}
      </div>
    );
  }
}
