import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../common/with-router";
import LoginState from "./login-state.component";
import ForgotPassword from "./forgotPassword-state.component";
import Register from "./register-state.component";
import Breadcrumb from "../common/breadcrumb-component";
import logo from "../../assets/logo.png";
import login from "../../assets/login.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChildEvent = this.handleChildEvent.bind(this);

    this.state = {
      formState: "login",
    };
  }

  handleChildEvent(state) {
    this.setState({ formState: state });
  }

  render() {
    return (
      <div>
        <Breadcrumb title="" />
        <div className="authentication-area bg-light">
          <div className="container">
            <div className="row min-vh-80 align-items-center">
              <div className="col-12">
                <div className="wrapper shadow-md radius-lg bg-white">
                  <div className="row align-items-center">
                    <div className="col-lg-6 bg-primary-light">
                      <div className="content">
                        <div className="logo mb-3 p-30">
                          <a href="index.html" target="_self" title="Teeno">
                            <img
                              src={logo}
                              alt="Logo"
                              style={{ display: "none" }}
                            />
                          </a>
                        </div>
                        <div className="svg-image">
                          <img alt="logo-img" src={login} data-src={login} />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="main-form">
                        {/* <a
                          href="index.html"
                          className="icon-link"
                          title="Go back to home"
                          target="_self"
                        >
                          <FontAwesomeIcon className="fal" icon={faHome} />
                        </a> */}

                        <Link
                          to="/"
                          className="icon-link"
                          title="Go back to home"
                        >
                          <FontAwesomeIcon className="fal" icon={faHome} />
                        </Link>
                        {this.state.formState === "login" && (
                          <LoginState onChildEvent={this.handleChildEvent} />
                        )}
                        {this.state.formState === "forgot-password" && (
                          <ForgotPassword
                            onChildEvent={this.handleChildEvent}
                          />
                        )}
                        {this.state.formState === "register" && (
                          <Register onChildEvent={this.handleChildEvent} />
                        )}
                        {/* <div className="title">
                          <h3 className="mb-30">Login to GoMobileBuild</h3>
                        </div>
                        <form id="#authForm">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group mb-20">
                                <label
                                  htmlFor="userName"
                                  className="form-label color-dark"
                                >
                                  Username Name
                                  <span className="color-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="user_name"
                                  id="userName"
                                  className="form-control"
                                  placeholder="Username"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group mb-20">
                                <label
                                  htmlFor="password"
                                  className="form-label color-dark"
                                >
                                  Password
                                  <span className="color-red">*</span>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    required
                                  />
                                  <span className="show-password-field">
                                    <i className="show-icon"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="custom-checkbox mb-10 font-sm">
                                <input
                                  className="input-checkbox"
                                  type="checkbox"
                                  name="checkbox"
                                  id="checkbox4"
                                  value=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkbox4"
                                >
                                  <span>
                                    I agree with GoMobileBuild's{" "}
                                    <a href="terms-conditions.html">
                                      Terms & Conditions
                                    </a>
                                  </span>
                                </label>
                              </div>
                              <div className="custom-checkbox mb-10 font-sm">
                                <input
                                  className="input-checkbox"
                                  type="checkbox"
                                  name="checkbox"
                                  id="checkbox5"
                                  value=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkbox5"
                                >
                                  <span>
                                    I’d like being informed about latest news
                                    and tips
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-10 mb-15">
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary w-100"
                              title="Login"
                            >
                              Login
                            </button>
                          </div>
                          <div className="d-flex justify-content-between flex-wrap gap-2">
                            <div className="link font-sm">
                              <a href="error-404.html" title="Forgot Password">
                                Forgot password?
                              </a>
                            </div>
                            <div className="link font-sm">
                              Don't have an account?{" "}
                              <a
                                href="signup.html"
                                title="Go Signup"
                                target="_self"
                              >
                                Click Here
                              </a>{" "}
                              to Signup
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12">
          <div className="card card-container">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            {this.state.formState === "login" && (
              <LoginState onChildEvent={this.handleChildEvent} />
            )}
            {this.state.formState === "forgot-password" && (
              <ForgotPassword onChildEvent={this.handleChildEvent} />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(Login);
