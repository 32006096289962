import React, { Component } from "react";
import { withRouter } from "../../common/with-router";

class BuildSettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: this.props.settings,
    };
  }

  componentDidMount() {}

  render() {
    const { settings } = this.state;
    return (
      <div className="container">
        {settings && settings.appinfo && settings.appinfo.appname}
      </div>
    );
  }
}

export default withRouter(BuildSettingsComponent);
