//This is importing axios module
import axios from "axios";
import { API_URL, API_URL_EXECUTE } from "../common/constants";
import authHeader from "./auth-header";

//This is a service class that has several methods to interact with authentication API endpoints
class BuildService {
  // Base configuration
  baseConfig = {
    baseURL: API_URL + API_URL_EXECUTE,
    headers: authHeader(),
  };
  multiPartConfig = {
    baseURL: API_URL + API_URL_EXECUTE,
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  // Method to create requests
  createRequest(url, data = {}, Aconf = this.baseConfig) {
    return axios.post(url, data, Aconf);
  }

  CreateApp(aEmail, aAppName, aAppType, AUrl) {
    const data = {
      p_email: aEmail,
      p_appname: aAppName,
      p_apptype: aAppType,
      p_url: AUrl,
    };
    return this.createRequest("/CreateApp", data);
  }

  GetAppSettings(aAppId) {
    const data = { p_appid: aAppId };
    return this.createRequest("/GetAppSettings", data);
  }

  GetAppStatus(aAppId) {
    const data = { p_appid: aAppId };
    return this.createRequest("/GetAppStatus", data);
  }

  SaveSettings(aAppId, aFiles, aSettings) {
    const data = { p_appid: aAppId, p_version: 1, p_settings: aSettings };
    const formData = new FormData();

    if (aFiles[0]) {
      formData.append("icon", aFiles[0]);
    }
    if (aFiles[1]) {
      formData.append("splashscreen", aFiles[1]);
    }
    formData.append("json", JSON.stringify(data));
    return this.createRequest(
      "/SetAppSettings",
      formData,
      this.multiPartConfig
    );
  }

  BuildApp(aAppId) {
    const data = { p_appid: aAppId };
    return this.createRequest("/BuildApp", data);
  }
}

//Exporting an instance of the Authservice Class
export default new BuildService();
