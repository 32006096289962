export const RequestMessageParser = (error) => {
  let resMessage = "Something went wrong. Please try again later.";
  const errorMessages = {
    40150: "Invalid credentials",
    40102: "Token expired",
    40151: "I'm sorry, it appears that your account has been blocked",
    40111:
      "We've sent an activation link to your email. Please check your inbox (and spam folder) to activate your account. If you don't receive it, click 'Resend Activation Link'. Contact us if you have questions. Thanks!",
    40112:
      "The email address you entered is already registered. If this is your email, please log in or use the 'Forgot your password?' option. Otherwise, please use a different email address.",
    40114:
      "This account is already activated. Please proceed to log in. If you have forgotten your password, please use the 'Forgot password?' option.",
    40113: "User does not exist. Please enter a valid email address.",
  };

  if (
    error &&
    error.code !== "ECONNABORTED" &&
    error.response &&
    error.response.data &&
    error.response.data.code &&
    errorMessages[error.response.data.code]
  ) {
    resMessage = errorMessages[error.response.data.code];
  }

  return resMessage;
};
