export default function authHeader(tokenType) {
  const user = JSON.parse(localStorage.getItem(tokenType));

  if (user && user.Token) {
    // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    return { token: user.Token }; // for Node.js Express back-end
  } else {
    return { token: "public" };
  }
}
