import React from "react";
import { withRouter } from "../common/with-router";
import { API_URL } from "../common/constants";
import { RequestMessageParser } from "../common/message-parser";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Breadcrumb from "./common/breadcrumb-component";

class UploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      AppName: "Blic",
      VersionCode: "1.0",
      loading: false,
      message: "",
      test: null,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFileChange(event) {
    this.setState({
      file: event.target.files[0],
      test: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleTitleChange(event) {
    this.setState({
      AppName: event.target.value,
    });
  }

  handleDescriptionChange(event) {
    this.setState({
      VersionCode: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    // if (!this.state.file) {
    //   console.log("No image");
    //   return;
    // }
    const jsonBody = JSON.stringify({
      appName: this.state.AppName,
      versionCode: this.state.VersionCode,
    });

    const formData = new FormData();
    formData.append("image", this.state.file);
    formData.append("icon", this.state.file);
    formData.append("jsonBody", jsonBody);

    const url = API_URL + "/appSaveSettings";

    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
    this.setState({ loading: true });
    axios
      .post(url, formData, { headers })
      .then((response) => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          message: RequestMessageParser(error),
        });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb title="Settings" />
        <div className="container">
          <Card>
            <Card.Body>
              <form onSubmit={this.handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="appName" className="form-label">
                    App Name:
                    <div className="info-text">
                      Your App Name is displayed on the device home screen.
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="appName"
                    placeholder="Example App"
                    value={this.state.AppName}
                    onChange={(event) =>
                      this.setState({ AppName: event.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="versionCode" className="form-label">
                    Version Code:
                    <div className="info-text">Your App version code.</div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="versionCode"
                    placeholder="Version Code"
                    value={this.state.VersionCode}
                    onChange={(event) =>
                      this.setState({ VersionCode: event.target.value })
                    }
                  />
                </div>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">
                        Icon:
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        onChange={this.handleFileChange}
                        id="image"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {this.state.test && (
                      <div className="App">
                        <img
                          alt="test"
                          className="img-thumbnail upload-image"
                          src={this.state.test}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <br></br>
                {this.state.message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}
                <br></br>
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  disabled={this.state.loading}
                >
                  <span>Save Settings</span>
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm spinner-margin"></span>
                  )}
                </button>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
export default withRouter(UploadPage);
