import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { RequestMessageParser } from "../../common/message-parser";
import AuthService from "../../services/auth.service";
import backImage from "../../assets/back.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      successful: false,
      message: "",
      errorConfirmPasswordMessage: "",
      loading: false,
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  clearState() {
    this.setState({
      formState: "login",
      username: "",
      password: "",
      loading: false,
      message: "",
      reactivation: false,
      forgetPassword: false,
    });
  }

  handleStateClick(state) {
    this.props.onChildEvent(state);
    if (state === "login") {
      this.clearState();
    }
  }

  handleBackClick() {
    this.props.onChildEvent("login");
  }

  handleRegister(e) {
    e.preventDefault();
    this.setState({ message: "" });
    this.form.validateAll();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorConfirmPasswordMessage: "Passwords do not match" });
      return;
    } else {
      this.setState({ errorConfirmPasswordMessage: "" });
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({
        message: "",
        successful: false,
        loading: true,
      });
      AuthService.register(this.state.email, this.state.password).then(
        (response) => {
          this.setState({ successful: true });
          this.setState({
            message:
              "We've sent an activation link to your email. Please check your inbox (and spam folder) to activate your account. If you don't receive it, click 'Resend Activation Link'. Contact us if you have questions. Thanks!",
            loading: false,
          });
        },
        (error) => {
          this.setState({
            successful: false,
            message: RequestMessageParser(error),
            loading: false,
          });
        }
      );
    }
  }

  render() {
    return (
      <div>
        <img
          className="back-button"
          onClick={this.handleBackClick}
          alt="back"
          src={backImage}
        />
        <Form
          onSubmit={this.handleRegister}
          ref={(c) => {
            this.form = c;
          }}
        >
          <div className="title">
            <h3 className="mb-30">Signup to GoMobileBuild</h3>
          </div>
          {!this.state.successful && (
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-20">
                    <label htmlFor="email" className="form-label color-dark">
                      Email<span className="color-red">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      validations={[required, email]}
                      id="email"
                      placeholder="Your email address"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-20">
                    <label htmlFor="password" className="form-label color-dark">
                      Password<span className="color-red">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required, vpassword]}
                        placeholder="Enter password"
                        required
                      />
                      <span className="show-password-field">
                        <i className="show-icon"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-20">
                    <label
                      htmlFor="confirmPassword"
                      className="form-label color-dark"
                    >
                      Confirm Password<span className="color-red">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.onChangeConfirmPassword}
                        validations={[required]}
                        id="confirmPassword"
                        placeholder="Confirm password"
                        required
                      />
                      <span className="show-password-field">
                        <i className="show-icon"></i>
                      </span>
                      {this.state.errorConfirmPasswordMessage && (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorConfirmPasswordMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="custom-checkbox font-sm">
                    <input
                      className="input-checkbox"
                      type="checkbox"
                      name="checkbox"
                      id="checkbox4"
                      value=""
                    />
                    <label className="form-check-label" htmlFor="checkbox4">
                      <span>
                        I agree with Gomobilebuild's{" "}
                        <a href="terms-conditions.html">Terms & Conditions</a>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center mt-20">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100"
                  title="Signup"
                  disabled={this.state.loading}
                >
                  Signup
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm spinner-margin"></span>
                  )}
                </button>
                <div className="link font-sm mt-15">
                  Already a member?{" "}
                  <label
                    title="Login Now"
                    onClick={() => this.handleStateClick("login")}
                  >
                    Login Now
                  </label>
                </div>
              </div>
              {/* <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  validations={[required, email]}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required, vpassword]}
                />
              </div> */}
              {/* 
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm password
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.onChangeConfirmPassword}
                  validations={[required]}
                />
              </div>
              {this.state.errorConfirmPasswordMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.errorConfirmPasswordMessage}
                </div>
              )}

              <div className="mb-3">
                <button
                  className="btn btn-primary btn-block"
                  disabled={this.state.loading}
                >
                  Sign Up
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm spinner-margin"></span>
                  )}
                </button>
              </div> */}
            </div>
          )}

          {this.state.message && (
            <div className="mb-3 mt-3">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}
              </div>
            </div>
          )}
          <CheckButton
            style={{ display: "none" }}
            ref={(c) => {
              this.checkBtn = c;
            }}
          />
        </Form>
      </div>
    );
  }
}
