import React, { Component } from "react";

export default class RestrictedPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    <h3>Restricted</h3>
                </header>
            </div>
        );
    }
}
