import React, { Component } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
import "./css/main.css";
import "./css/animate.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthService from "./services/auth.service";
import Login from "./components/login/login.component";
import Register from "./components/login/register-state.component";
import Home from "./components/home/home.component";
import Profile from "./components/profile.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import RouteAuth from "./common/RouteAuth";
import RestrictedPage from "./components/restricted.component";
import ActivationPage from "./components/login/activation.component";
import ResetPasswordPage from "./components/login/resetPassword.component";
import PreBuildComponent from "./components/build/prebuild-component";
import BuildComponent from "./components/build/build-component";
import UploadPage from "./components/upload";
import PaypallPage from "./components/paypal";
import Authentication from "./components/Authentication";
import NotFound from "./components/NotFound";
class App extends Component {
  constructor(props) {
    super(props);
    // console.log(props.router.location);
    this.logOut = this.logOut.bind(this);
    this.restricted = this.restricted.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user, //,
        //  showModeratorBoard: user.roles.includes("MODERATOR"),
        showAdminBoard: user.roles.includes("ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });

    const jScript = document.createElement("script");
    jScript.src = "assets/js/jquery.min.js";
    jScript.async = true;

    jScript.onload = () => {
      const wowScript = document.createElement("script");
      wowScript.src = "assets/js/wow.min.js";
      wowScript.async = true;
      wowScript.onload = () => {
        const scrollScript = document.createElement("script");
        scrollScript.src = "assets/js/jquery.scrollUp.min.js";
        scrollScript.async = true;
        scrollScript.onload = () => {
          const menuScript = document.createElement("script");
          menuScript.src = "assets/js/jquery.meanmenu.min.js";
          menuScript.async = true;
          menuScript.onload = () => {
            const mScript = document.createElement("script");
            mScript.src = "assets/js/main.js";
            mScript.async = true;
            document.body.appendChild(mScript);
          };
          document.body.appendChild(menuScript);
        };
        document.body.appendChild(scrollScript);
      };
      document.body.appendChild(wowScript);
    };

    document.body.appendChild(jScript);
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  restricted() {}

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>
        {/* header_area_start */}
        <header>
          <div className="header-transparent header-sticky">
            <div className="header-main">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-4 col-6">
                    <div className="sasup-logo mr-35 d-inline-block">
                      <Link to={"/"} className="logo-1">
                        {" "}
                        <img
                          src="./Sasup - Sass Landing HTML5 Template_files/logo-white.png"
                          alt=""
                        />
                      </Link>
                      {/* <a href="#" className="logo-1">
                        <img
                          src="./Sasup - Sass Landing HTML5 Template_files/logo-white.png"
                          alt=""
                        />
                      </a> */}
                      <Link to={"/"} className="logo-1">
                        {" "}
                        <img
                          src="./Sasup - Sass Landing HTML5 Template_files/logo.png"
                          alt=""
                        />
                      </Link>
                      {/* <a href="#" className="logo-2">
                        <img
                          src="./Sasup - Sass Landing HTML5 Template_files/logo.png"
                          alt=""
                        />
                      </a> */}
                    </div>
                    <div className="sasup-header d-none d-lg-inline-block">
                      <nav id="mobile-menu" style={{ display: "block" }}>
                        <ul>
                          <li>
                            <Link to={"/"}>Home</Link>
                          </li>
                          {/* <li>
                            <Link to={"/settings"} className="nav-link">
                              Settings
                            </Link>
                          </li> */}

                          {showModeratorBoard && (
                            <li>
                              <Link to={"/mod"} className="nav-link">
                                Moderator Board
                              </Link>
                            </li>
                          )}

                          {showAdminBoard && (
                            <li>
                              <Link to={"/admin"}>Admin</Link>
                            </li>
                          )}

                          {currentUser && (
                            <li>
                              <Link to={"/profile"}>Profile</Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-8 col-6">
                    {/* <Link
                        to={"/login"}
                        className="sasup-transparent-btn d-none d-sm-inline-block"
                      >
                        <FontAwesomeIcon
                          className="icon-with-button"
                          icon={faUser}
                        />
                        Log in
                      </Link> */}

                    {currentUser ? (
                      <div className="sasup-header-action-btn">
                        {/* 
                          USERNAME
                          <Link 
                            to={"/profile"}
                            className="sasup-transparent-btn d-none d-sm-inline-block"
                          >
                            <FontAwesomeIcon
                              className="icon-with-button"
                              icon={faUser}
                            />
                            {currentUser.username}
                          </Link> */}

                        <a
                          href="/login"
                          className="sasup-theme-btn ml-25 d-none d-sm-inline-block"
                          onClick={this.logOut}
                        >
                          <FontAwesomeIcon
                            className="icon-with-button"
                            icon={faRightFromBracket}
                          />
                          LogOut
                        </a>
                        <div className="mobile-bar-control mobile-bar-control-white d-inline-block d-lg-none">
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="sasup-header-action-btn">
                        <Link
                          to={"/login"}
                          className="sasup-theme-btn ml-25 d-none d-sm-inline-block"
                        >
                          <FontAwesomeIcon
                            className="icon-with-button"
                            icon={faUser}
                          />
                          Login
                        </Link>
                        <div className="mobile-bar-control mobile-bar-control-white d-inline-block d-lg-none">
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>

                        {/* 
                          <Link
                            to={"/register"}
                            className="sasup-theme-btn ml-25 d-none d-sm-inline-block"
                          >
                            <span>Sign Up</span>
                          </Link> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* header_area_start */}
        {/* responsive sidebar start */}
        <div className="responsive-sidebar d-block d-lg-none">
          <div className="responsive-sidebar-inner">
            <div className="logo mb-30">
              <div className="row">
                <div className="col-6">
                  <img
                    src="./Sasup - Sass Landing HTML5 Template_files/logo.png"
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <div className="text-end">
                    <button className="responsive-sidebar-close">
                      <FontAwesomeIcon className="fal" icon={faTimes} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="resposive-sidebar-menu mb-50">
              <div className="mobile-menu"></div>
            </div>
            <div className="responsive-sidebar-actions">
              <Link
                to={"/login"}
                className="sasup-border-btn d-block sasup-broder-btn-space-3 ms-0 text-center mb-2"
              >
                Login
              </Link>
              <Link
                to={"/login"}
                className="sasup-theme-btn text-center d-inline-block d-sm-none w-100"
              >
                SignUp
              </Link>
            </div>
          </div>
        </div>
        {/* responsive sidebar start */}
        {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            GoMobileBuild
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/settings"} className="nav-link">
                Settings
              </Link>
            </li>

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  Profile
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="ms-auto pe-md-5 navbar-nav">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="ms-auto pe-md-5 navbar-nav">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav> */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />

            <Route
              path="/mod"
              element={
                <RouteAuth>
                  <BoardModerator title={"Moderator"} />
                </RouteAuth>
              }
            />

            <Route
              path="/admin"
              element={
                <RouteAuth role={["ADMIN", "USER"]}>
                  <BoardAdmin />
                </RouteAuth>
              }
            />
            <Route path="/restricted" element={<RestrictedPage />} />
            <Route path="/activation/:code" element={<ActivationPage />} />
            <Route
              path="/resetpassword/:code"
              element={<ResetPasswordPage />}
            />
            <Route
              path="/prebuild/:url/:appName"
              element={<PreBuildComponent />}
            />
            <Route path="/build/:appId" element={<BuildComponent />} />
            <Route path="/settings" element={<UploadPage />} />
            <Route path="/paypal" element={<PaypallPage />} />
            <Route path="/auth" element={<Authentication />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
            {/*<Route exact path="/admin" component={withAuth(BoardAdmin, ['admin'])} />*/}
          </Routes>
        </main>
        {<AuthVerify logOut={this.logOut} />}
      </div>
    );
  }
}

export default App;
