//Importing necessary modules
import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";

//Defining RouteAuth function component with a props object containing children and role properties
function RouteAuth({ children, role }: { children: React.CElement }) {
  // Get the current page location using "useLocation()"
  let location = useLocation();
  // Retrieving current user details via the "getCurrentUser()" method defined in AuthService.
  const user = AuthService.getCurrentUser();

  if (user) {
    // If role is passed as a prop & User has that role access then allow page to be rendered
    // Otherwise, redirect them to '/restricted' page, passing current location along so they don't lose it
    if (role && !user.roles.some((value) => role.includes(value))) {
      return <Navigate to="/restricted" state={{ from: location }} replace />;
    }
  } else {
    // If user details are not retrieved yet, Redirect them to Login page, saving current location so they can return after login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render children received through props
  // Only runs when user already logged in and have required role to access this component
  return children;
}

export default RouteAuth;
