import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Breadcrumb from "./common/breadcrumb-component";
import Card from "react-bootstrap/Card";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { currentUser } = this.state;

    return (
      <div>
        <Breadcrumb title="Profile" />
        <div className="container">
          <Card>
            <Card.Body>
              {this.state.userReady ? (
                <div>
                  <p>
                    <strong>Token:</strong> {currentUser.token.substring(0, 20)}{" "}
                    ...{" "}
                    {currentUser.token.substr(currentUser.token.length - 20)}
                  </p>
                  <p className="ubnowrap">
                    <strong>Token:</strong> {currentUser.token}
                  </p>
                  <p>
                    <strong>Email:</strong> {currentUser.username}
                  </p>
                  <strong>Authorities: </strong>
                  ??????????
                  <ul>
                    {currentUser.roles &&
                      currentUser.roles.map((role, index) => (
                        <li key={index}>{role}</li>
                      ))}
                  </ul>
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
