import React, { Component } from "react";
import FeaturesSection from "./features-section.component";
import HeroSection from "./hearo-section.component";

export class Home extends Component {
  render() {
    return (
      <div>
        <HeroSection />
        {/* <FeaturesSection /> */}
      </div>
    );
  }
}
export default Home;
