import React, { Component } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { withRouter } from "../common/with-router";

class PayPalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: "",
      paymentStatus: "",
    };
  }

  handlePaymentSuccess = () => {
    // Update the payment status in the state
    this.setState({ paymentStatus: "Success" });
  };

  handlePaymentApproval = (data, actions) => {
    // Capture the funds from the approved order
    return actions.order.capture().then((details) => {
      // Call your own API to save the transaction details
      console.log(details);
      // Update the payment status in the state
      this.setState({ paymentStatus: "Approved" });
    });
  };

  render() {
    const { paymentStatus } = this.state;

    return (
      <div className="container">
        <PayPalScriptProvider
          options={{
            "client-id":
              "AfkbWq6mq5eqWdmCv62XEzSnF6BGdBMdIVbmXT4VdCD3a3dRxKr_22b2B6PrFsRbs_lk8_DHS-AqVjC5",
          }}
        >
          {paymentStatus !== "Success" && ( // Hide buttons if paymentStatus is "Success"
            <PayPalButtons
              createOrder={(data, actions) => {
                // Create an order and return the order ID
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: "5.00", // Example: The payment amount
                      },
                      custom_id: "test_milos",
                    },
                  ],
                });
              }}
              onApprove={this.handlePaymentApproval}
              onSuccess={this.handlePaymentSuccess}
            />
          )}
        </PayPalScriptProvider>

        {paymentStatus && (
          <div className="notification">
            Payment {paymentStatus === "Success" ? "successful!" : "approved!"}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PayPalComponent);
